import React from 'react';
import Router from './../router'

const Content = () =>  {
    return(
      <React.Fragment>
        <div className='container-fluid'>
          <Router />
        </div>
      </React.Fragment>
    )    
}

export default Content;
