import {fork, all} from 'redux-saga/effects';
import {
  fetchCustomerSaga,
  fetchFirstCustomerSaga
} from '../db/saga/customer'

const sagas = [
  fetchCustomerSaga,
  fetchFirstCustomerSaga
];

function* globalSagas() {
  const forks = sagas.map(saga => fork(saga));
  yield all([...forks]);
}

export default globalSagas;
