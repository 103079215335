import {
    FETCH_CUSTOMER_REQUEST,
    FETCH_FIRST_CUSTOMER_REQUEST,
}  from '../actionTypes/customer';

export const fetchCustomerRequest = ({ org, search_key,search_value }) => ({
    type: FETCH_CUSTOMER_REQUEST,
    payload: { org, search_key,search_value }
});

export const fetchFirstCustomerRequest = ({ org }) => ({
    type: FETCH_FIRST_CUSTOMER_REQUEST,
    payload: { org }
});
