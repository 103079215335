import $http from '../../meta/http';

export const fetchOrg = async ({org, search_key, search_value}) => {
  return $http.get(`/customer-data?org=${org}&search_key=${search_key}&search_value=${search_value}`)
              .then(response => response)
}

export const fetchFirstCustomer = async ({org}) => {
  return $http.get(`/first-customer-data?org=${org}`)
              .then(response => response)
}
