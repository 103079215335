import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
const RecommendationTab = (props) =>  {
const RecommendationData  = props.customerData.Recommendation;

return ( <>
        <div className="card shadow mb-4">
            <div className="card-header py-3">
            <div className="row">
                <div className="col-sm">
                  <h5 className="m-0 font-weight-bold text-info">Recommendation</h5>
                </div>
            </div>
            </div>
            <div className="card-body">
            <BootstrapTable data={RecommendationData} striped hover options={ { noDataText: 'No details found' } } pagination>
                <TableHeaderColumn dataField='Date' isKey={true} dataSort={true} filter={{ type: 'TextFilter', delay: 500 }}>Date</TableHeaderColumn>
                <TableHeaderColumn dataField='Product' dataSort={true} filter={{ type: 'TextFilter', delay: 500 }}>Product</TableHeaderColumn>
                <TableHeaderColumn dataField='Description' dataSort={true} filter={{ type: 'TextFilter', delay: 500 }}>Description</TableHeaderColumn>
                <TableHeaderColumn dataField='Price' dataSort={true} filter={{ type: 'TextFilter', delay: 500 }}>Price</TableHeaderColumn>
            </BootstrapTable>
            </div>
        </div>
 </> )
}

export default RecommendationTab;
