import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Customer from '../../customer'

const Router = () => {
    return(
        <Switch>
            <React.Fragment>
              <Route exact path='/' component={Customer} />
              <Route path='/:org' component={Customer} />
            </React.Fragment>
        </Switch>)
}

export default Router;