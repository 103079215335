import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Footer from './../footer';
import Content from './../content';

const Home = () => {
    return (
      <BrowserRouter basename='/' > 
        <React.Fragment>  
          < Content />
          < Footer />
        </React.Fragment>
      </BrowserRouter>
    ) 
}

export default Home;
