import React from 'react';
import Home from './main/home';
import Loading from '../components/loading';
import '../style/index.scss'

const App = () => {
  return (
      <React.Fragment>
        <Loading />
        <Home />
      </React.Fragment>
  )    
}


export default App;
