import {
    FETCH_CUSTOMER_REQUEST,
    FETCH_FIRST_CUSTOMER_REQUEST,
    FETCH_CUSTOMER_SUCCESS
  }
  
  from '../actionTypes/customer';
  
  export default function customerReducer(state = {}, action) {
    switch (action.type) {

      case FETCH_CUSTOMER_REQUEST:
      return {
        items: {
        }
      };

      case FETCH_FIRST_CUSTOMER_REQUEST:
      return {
        items: {
        }
      };
  
      case FETCH_CUSTOMER_SUCCESS:
      return {
        list: action.payload || {}
      }  

      default:
        return state;
    }
  }
  