import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import avatar from '../../asset/image/avatar.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tabs, Tab } from 'react-bootstrap';
import RecommendationTab from './../../components/Recommendation'
import IncartTab from './../../components/Incart'
import InteractionsTab from './../../components/Interactions'
import BoughtTab from './../../components/Bought'
import Top from './../../layout/main/top'

const  CustomerContainer = (props) => {

  const { CustomerName, Address, Email, MobileNumber, CustomerCategory,
  LifeTimeValue, LTVwidth, SpendGap, SGwidth, 
  LastInteractionDate, InCart, Interactions, Recommendation, Bought, Currency, CustomerCode  } = props.customerData || {};
  return (
      <>
      <Top {...props} />
      {
      CustomerCode && ( 
      <>
      <div className="row">
        <div className="col-md-3 col-sm-3">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col-auto mr-3">
                  <img className="img-profile rounded-circle img-60" src={avatar}/>
                </div>
                <div className="col">
                  <div className="text-lg font-weight-bold text-info text-uppercase mb-1">Customer Name</div>
                  <div id="CustomerName" className="h5 mb-0 font-weight-normal text-gray-800">{CustomerName}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="col-md-3 col-sm-3">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col-auto mr-3 text-gray-400">
                  <FontAwesomeIcon icon="map-marker-alt" size="2x" />
                </div>
                <div className="col">
                  <div className="text-lg font-weight-bold text-info text-uppercase mb-1">Address</div>
                    <div id="CustomerAddreaa" className="h5 mb-0 font-weight-normal text-gray-800">{Address}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="col-md-3 col-sm-3">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col-auto mr-3 text-gray-400">
                <FontAwesomeIcon icon="envelope" size="2x" />
                </div>
                <div className="col">
                  <div className="text-lg font-weight-bold text-info text-uppercase mb-1">E-Mail</div>
                    <div id="CustomerEmail" className="h5 mb-0 font-weight-normal text-gray-800">{Email}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="col-md-3 col-sm-3">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col-auto mr-3 text-gray-400">
                  <FontAwesomeIcon icon="mobile" size="2x" />
                </div>
                <div className="col">
                  <div className="text-lg font-weight-bold text-info text-uppercase mb-1">Mobile Number</div>
                    <div id="CustomerMobileNo" className="h5 mb-0 font-weight-normal text-gray-800">{MobileNumber}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>
        <div className="mt-4">
      <div className="row">
         <div className="col-md-3 col-sm-6">
             <div className="mb-4">
                <div className="card-header bg-gray-200 py-3">
                  <h6 className="m-0 font-weight-bold text-gray-600">Customer Category</h6>
                </div>
                <div className="card-body">
                  <h4 id="CustCategory" className="text-xl font-weight-bold text-info">{CustomerCategory}</h4>
                </div>
              </div>
         </div>
         <div className="col-md-3 col-sm-6">
             <div className="mb-4">
                <div className="card-header bg-gray-200 py-3">
                  <h6 className="m-0 font-weight-bold text-gray-600">Life Time Value</h6>
                </div>
                <div className="card-body">
                    <h4 id="CustLifeTimeval" className="text-xl font-weight-bold text-info">{Currency} {LifeTimeValue}</h4>
                  <div className="progress mb-4">
                    <div className="progress-bar bg-info" role="progressbar" id="LifeTimeValuewidth" style={{ width: `${LTVwidth}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
         </div>
         <div className="col-md-3 col-sm-6">
             <div className="mb-4">
                <div className="card-header bg-gray-200 py-3">
                  <h6 className="m-0 font-weight-bold text-gray-600">Spend Gap</h6>
                </div>
                <div className="card-body">
                    <h4 id="CustSpendGap" className="text-xl font-weight-bold text-info">{Currency} {SpendGap}</h4>
                  <div className="progress mb-4">
                      <div className="progress-bar bg-info" role="progressbar" id="CustSpendGapwidth" style={{ width: `${SGwidth}%` }} aria-valuenow="35" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
         </div>
         <div className="col-md-3 col-sm-6">
             <div className="mb-4">
                <div className="card-header bg-gray-200 py-3">
                  <h6 className="m-0 font-weight-bold text-gray-600">Last Interaction Date</h6>
                </div>
                <div className="card-body">
                    <h4 id="CustLastInteractionDate" className="text-xl font-weight-bold text-info">{LastInteractionDate}</h4>
                </div>
              </div>
         </div>
         </div>
     </div> 
        <Tabs defaultActiveKey="recommendation" id="uncontrolled-tab-example">
        { Recommendation && Recommendation.length > 0 && <Tab eventKey="recommendation" title="Recommendation">
          <RecommendationTab {...props} />
        </Tab> }
        { InCart && InCart.length > 0 && <Tab eventKey="incart" title="Incart">
          <IncartTab {...props} />
        </Tab> }
        { Interactions && Interactions.length > 0 && <Tab eventKey="interactions" title="Interactions">
          <InteractionsTab {...props} />
        </Tab> }
        { Bought && Bought.length > 0 && <Tab eventKey="bought" title="Bought">
          <BoughtTab {...props} />
        </Tab>  }
        </Tabs>
       </>
      )
      }
      {
        !CustomerCode && <h3 className='mt-5'>
          <center> Customer not found or search customer by Email / Mobile / Name </center></h3>
      }
      </>    
    );
  
}

const mapStateToProps = state => ({
    customerData : state.Customer.list || {}
});

function matchDispatchToProps(dispatch) {
return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(CustomerContainer));

