import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
const IncartTab = (props) =>  {
const IncartData  = props.customerData.InCart;

return ( <>
        <div className="card shadow mb-4">
            <div className="card-header py-3">
            <div className="row">
                <div className="col-sm">
                  <h5 className="m-0 font-weight-bold text-info">InCart</h5>
                </div>
            </div>
            </div>
            <div className="card-body">
            <BootstrapTable data={IncartData} striped hover options={ { noDataText: 'No details found' } } pagination>
                <TableHeaderColumn dataField='Product' isKey={true} dataSort={true} filter={{ type: 'TextFilter', delay: 500 }}>Product</TableHeaderColumn>
                <TableHeaderColumn dataField='Quanity' dataSort={true} filter={{ type: 'TextFilter', delay: 500 }}>Quantity</TableHeaderColumn>
                <TableHeaderColumn dataField='Price' dataSort={true} filter={{ type: 'TextFilter', delay: 500 }}>Price</TableHeaderColumn>
                <TableHeaderColumn dataField='Description' dataSort={true} filter={{ type: 'TextFilter', delay: 500 }}>Description</TableHeaderColumn>
            </BootstrapTable>
            </div>
        </div>
 </> )
}

export default IncartTab;
