import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
const BoughtTab = (props) =>  {
const BoughtData  = props.customerData.Bought && props.customerData.Bought[0];
const { BoughtTotalAmount, BoughtTotalItems, Currency} = props.customerData;

return ( <>
        <div className="card shadow mb-4">
            <div className="card-header py-3">
            <div className="row">
                <div className="col-sm">
                  <h5 className="m-0 font-weight-bold text-info">Bought</h5>
                </div>
                <div className="col-sm">
                  <h5 className="m-0 font-weight-bold text-gray-600">Total Amount Bought: </h5>
                  <h5 id="totalAmountbought" className="m-0 font-weight-bold text-info">{Currency} {BoughtTotalAmount} </h5>
                </div>
                <div className="col-sm">
                  <h5 className="m-0 font-weight-bold text-gray-600">Total No. of Buys: </h5>
                    <h5 id="totalNoOfBuys" className="m-0 font-weight-bold text-info">{BoughtTotalItems}</h5>
                </div>
            </div>
            </div>
            <div className="card-body">
            <BootstrapTable data={BoughtData} striped hover options={ { noDataText: 'No details found' } } pagination>
                <TableHeaderColumn dataField='Product' isKey={true} dataSort={true} filter={{ type: 'TextFilter', delay: 500 }}>Product</TableHeaderColumn>
                <TableHeaderColumn dataField='Quanity' dataSort={true} filter={{ type: 'TextFilter', delay: 500 }}>Quantity</TableHeaderColumn>
                <TableHeaderColumn dataField='Price' dataSort={true} filter={{ type: 'TextFilter', delay: 500 }}>Price</TableHeaderColumn>
                <TableHeaderColumn dataField='Description' dataSort={true} filter={{ type: 'TextFilter', delay: 500 }}>Description</TableHeaderColumn>
            </BootstrapTable>
            </div>
        </div>
 </> )
}

export default BoughtTab;
