import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { fetchCustomerRequest, fetchFirstCustomerRequest } from '../../../db/action/customer';

const Top = (props) =>  {

  const [search_key, setSearchKey] = useState('CustomerName');
  const [search_value, setSearchValue] = useState(null);

  const change = (event) => {
    setSearchKey(event.target.value);
  }

  const inputChange = (event) => {
    setSearchValue(event.target.value);
  }

  const processCustomer = () => {
    const { org } = props.match.params;
    if(search_value)
    props.fetchCustomerRequest({ org, search_key, search_value})
  }

  useEffect(() => {
    const { org } = props.match.params;
    if(!search_value)
      props.fetchFirstCustomerRequest({org})
  }, [])

return (    
<div className="container-fluid">    
<div className="row">
      <div className="col-xl-12">
         <div className="d-sm-flex align-items-center justify-content-between mb-4 mt-5">
            <h1 className="h2 font-weight-semi-bold mb-0 text-gray-800">Customer 360</h1>
            <form className="form-inline  mw-100 navbar-search">
            <div className="input-group">
              <div className="input-group-prepend">
                <label className="input-group-text" for="inputGroupSelect01">Search by</label>
              </div>
              <select className="custom-select" id="inputGroupSelect01" onChange={change} value={search_key}> 
                <option value="MobileNumber">MobileNumber</option>
                <option value="Email">Email</option>
                <option value="CustomerName">CustomerName</option>
                <option value="CustomerCode">CustomerCode</option>
              </select>
            </div>
            <div className="input-group ml-3">
                <input type="text" id="Search" className="form-control bg-light small"
                placeholder="value" aria-label=" search" onChange={inputChange} aria-describedby="basic-addon2"/>
              <div className="input-group-append">
                <button className="btn btn-primary" type="button" onClick={processCustomer}>
                    <FontAwesomeIcon icon="search" />
                </button>
              </div>
            </div>
          </form>
         </div>
         
       </div>
    </div>
   </div> 
    )
}

const mapStateToProps = state => ({
  customerData : state.Customer.list || {}
});


function matchDispatchToProps(dispatch) {
  return bindActionCreators({fetchCustomerRequest, fetchFirstCustomerRequest}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Top));
  

