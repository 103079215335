
import {put, call, takeLatest} from 'redux-saga/effects';
import {
    fetchOrg,
    fetchFirstCustomer,
} from '../../com/customer';

import {
    FETCH_CUSTOMER_REQUEST,
    FETCH_FIRST_CUSTOMER_REQUEST,
    FETCH_CUSTOMER_SUCCESS
}
from '../actionTypes/customer';

import { LOADING_PROGRESS, LOADING_SUCCESS } from '../actionTypes/loading';

export function* fetchCustomerSaga() {
    yield takeLatest(FETCH_CUSTOMER_REQUEST, workerSagaFetchCustomer);
}

export function* fetchFirstCustomerSaga() {
    yield takeLatest(FETCH_FIRST_CUSTOMER_REQUEST, workerSagaFetchFirstCustomer);
}

function* workerSagaFetchCustomer(data) {
    yield put({type: LOADING_PROGRESS });
    const response = yield call(fetchOrg, data.payload);
    const payload = response.data ? response.data.data: {}; 
    yield put({type: FETCH_CUSTOMER_SUCCESS, payload });
    yield put({type: LOADING_SUCCESS });
}  

function* workerSagaFetchFirstCustomer(data) {
    yield put({type: LOADING_PROGRESS });
    const response = yield call(fetchFirstCustomer, data.payload);
    const payload = response.data ? response.data.data: {}; 
    yield put({type: FETCH_CUSTOMER_SUCCESS, payload });
    yield put({type: LOADING_SUCCESS });
}  
