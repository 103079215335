import React from 'react';
import { ClipLoader } from 'react-spinners';
import { connect } from "react-redux";

const override = 'position: fixed;right: 20px;z-index: -1';

const Loading = (props) => {
    return (
        <React.Fragment> 
        { props.loading.isLoading === false &&      
        <div className='sweet-loading'>
          <ClipLoader
            css = {override}
            sizeUnit={"px"}
            color={"red"}
            loading={props.loading.isLoading}
          />
        </div> }
        </React.Fragment>
    )   
}

const mapStateToProps = state => ({
    loading:state.loading.items
});

export default connect(mapStateToProps)(Loading);